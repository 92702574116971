import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { serviceAPIParams } from 'helpers/Utils';
import { INTERNAL_SERVER_ERROR } from 'constants/defaultValues';
import {
  VEHICLE_LIST_REQUEST,
  VEHICLE_REGISTER_REQUEST,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_EDIT_REQUEST,
  listVehicleRequest,
  listVehicleSuccess,
  listVehicleError,
  registerVehicleSuccess,
  registerVehicleError,
  editVehicleError,
  deleteVehicleError,
  undeleteVehicleError,
  VEHICLE_UNDELETE_REQUEST,
} from './actions';

function* listVehicle(api, { payload }) {
  const vehicles = yield call(
    api.listVehicles,
    serviceAPIParams({
      ...payload.params,
    })
  );

  if (vehicles.ok) {
    const { vehicles: list, metadata } = vehicles.data;

    const { current: currentPage, total: totalPages } = metadata.pages;
    const { total: totalItems, skip: skipItems = 0 } = metadata.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listVehicleSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listVehicleError(message));
  }
}

function* registerVehicle(api, { payload }) {
  const apiRequest = yield call(api.post, payload.body);

  if (apiRequest.ok) {
    yield put(registerVehicleSuccess());
  } else {
    if (apiRequest.data.statusCode === INTERNAL_SERVER_ERROR) {
      apiRequest.data.message = 'Unable to create vehicle.';
    }
    yield put(registerVehicleError(apiRequest.data));
  }
  yield put(listVehicleRequest(payload.params));
}

function* deleteVehicle(api, { payload }) {
  const response = yield call(api.remove, payload.id);

  if (response.ok) {
    yield put(listVehicleRequest(payload.params));
  } else {
    if (response.data.statusCode === INTERNAL_SERVER_ERROR) {
      response.data.message = 'Unable to update vehicle.';
    }
    yield put(deleteVehicleError(response.data));
  }
}

function* editVehicle(api, { payload }) {
  const response = yield call(api.put, payload.id, payload?.body);

  if (response.ok) {
    yield put(listVehicleRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o veículo';
    yield put(editVehicleError(message));
  }
}

function* undeleteVehicle(api, { payload }) {
  const response = yield call(api.undelete, payload.id);

  if (response.ok) {
    yield put(listVehicleRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o veículo';
    yield put(undeleteVehicleError(message));
  }
}

export function* watchListVehicleRequest(api) {
  yield takeEvery(VEHICLE_LIST_REQUEST, listVehicle, api);
}

export function* watchRegisterVehicleRequest(api) {
  yield takeEvery(VEHICLE_REGISTER_REQUEST, registerVehicle, api);
}

export function* watchUDeleteVehicleRequest(api) {
  yield takeEvery(VEHICLE_DELETE_REQUEST, deleteVehicle, api);
}

export function* watchEditVehicleRequest(api) {
  yield takeEvery(VEHICLE_EDIT_REQUEST, editVehicle, api);
}

export function* watchUndeleteVehicleRequest(api) {
  yield takeEvery(VEHICLE_UNDELETE_REQUEST, undeleteVehicle, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListVehicleRequest, api),
    fork(watchRegisterVehicleRequest, api),
    fork(watchEditVehicleRequest, api),
    fork(watchUDeleteVehicleRequest, api),
    fork(watchUndeleteVehicleRequest, api),
  ]);
}
