import produce from 'immer';

import {
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
  VEHICLE_LIST_ERROR,
  VEHICLE_REGISTER_REQUEST,
  VEHICLE_REGISTER_SUCCESS,
  VEHICLE_REGISTER_ERROR,
  VEHICLE_EDIT_REQUEST,
  VEHICLE_EDIT_ERROR,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_DELETE_ERROR,
  VEHICLE_UNDELETE_REQUEST,
  VEHICLE_UNDELETE_ERROR,
} from './actions';

const INIT_STATE = {
  vehicles: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  loadingImportVehicle: false,
  successImportVehicle: false,
  errorImportVehicle: '',
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case VEHICLE_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case VEHICLE_LIST_SUCCESS: {
        draft.loading = false;
        draft.vehicles = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case VEHICLE_LIST_ERROR: {
        draft.loading = false;
        draft.vehicles = [];
        draft.error = action.payload.message;
        break;
      }
      case VEHICLE_REGISTER_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case VEHICLE_REGISTER_SUCCESS: {
        draft.loading = false;
        draft.loadingImportVehicle = false;
        draft.error = '';
        break;
      }
      case VEHICLE_REGISTER_ERROR: {
        draft.loading = false;
        draft.loadingImportVehicle = false;
        draft.error = action.payload.message;
        break;
      }
      case VEHICLE_DELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case VEHICLE_DELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case VEHICLE_EDIT_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case VEHICLE_EDIT_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case VEHICLE_UNDELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case VEHICLE_UNDELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
