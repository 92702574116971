import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import adminUsers from './adminUsers/reducer';
import orgUsers from './orgUsers/reducer';
import stationUsers from './stationUsers/reducer';
import organizations from './organizations/reducer';
import reports from './reports/reducer';
import maps from './maps/reducer';
import hydrate from './hydrate/reducer';
import transaction from './transaction/reducer';
import deposits from './deposits/reducer';
import moneyTransfer from './moneyTransfer/reducer';
import gasStations from './gasStations/reducer';
import vehicles from './vehicles/reducer';
import billingStatements from './billingStatements/reducer';
import permissions from './permissions/reducer';
import userPermissions from './userPermissions/reducer';
import discountRate from './discountRate/reducer';
import bills from './bills/reducer';
import balanceSharing from './balanceSharing/reducer';
import gasStationBillings from './gasStationBillings/reducer';
import fuelManagement from './fuelManagement/reducer';
import organizationInvoices from './organizationInvoices/reducer';
import purchase from './purchase/reducer';
import module from './module/reducer';
import subscriptions from './subscriptions/reducer';
import plans from './plans/reducer';
import menuPortal from './menuPortal/reducer';
import banks from './banks/reducer';
import customModules from './customModules/reducer';
import fraudSuspicions from './fraudSuspicion/reducer';
import fuelManagementUsers from './fuelManagementUsers/reducer';
import fuelManagementTransactions from './fuelManagementTransactions/reducer';
import gasStationList from './gasStationList/reducer';
import paymentAuthorization from './paymentAuthorization/reducer';
import gasStationInstallments from './gasStationInstallments/reducer';
import containers from './container/reducer';

const reducers = combineReducers({
  menu,
  settings,
  auth,
  user,
  adminUsers,
  orgUsers,
  stationUsers,
  organizations,
  reports,
  maps,
  hydrate,
  transaction,
  deposits,
  moneyTransfer,
  gasStations,
  vehicles,
  billingStatements,
  permissions,
  userPermissions,
  discountRate,
  bills,
  balanceSharing,
  gasStationBillings,
  fuelManagement,
  organizationInvoices,
  purchase,
  module,
  subscriptions,
  plans,
  menuPortal,
  banks,
  customModules,
  fraudSuspicions,
  fuelManagementUsers,
  fuelManagementTransactions,
  gasStationList,
  paymentAuthorization,
  gasStationInstallments,
  containers
});

export default reducers;
